<template>
    <div class="app-container">
        <el-tabs v-model="activeTabName" type="card" @tab-remove="removeTab">
            <el-tab-pane :label="$t('base.materiel.list')" :name="listTab.tabName" :key="listTab.tabName">
                <el-form role="form" label-width="100px">
                <el-row>
                    <el-col :span="6">
                        <el-form-item :label="$t('base.company.name')">
                            <el-select @change="changeCompany" v-model="listQuery.companyId" @clear="clearEmpty('companyId')" clearable :style="{width:'100%'}">
                                <el-option
                                        v-for="item in companyList"
                                        :key="item.companyId"
                                        :value="item.companyId"
                                        :label="item.companyName">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item :label="$t('base.project.name')">
                            <el-select :disabled="isAble" v-model="listQuery.projectId" clearable :style="{width:'100%'}">
                                <el-option
                                        v-for="item in projectList"
                                        :key="item.projectId"
                                        :value="item.projectId"
                                        :label="item.projectName">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item :label="$t('order.appDept')">
                            <el-select :disabled="isAble" v-model="listQuery.reportDeptId" clearable :style="{width:'100%'}">
                                <el-option
                                        v-for="item in departmentList"
                                        :key="item.deptId"
                                        :value="item.deptId"
                                        :label="item.deptName">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item :label="$t('order.appPerson')">
                            <el-select v-model="listQuery.reportUserId" clearable :style="{width:'100%'}">
                                <el-option
                                        v-for="item in employeeList"
                                        :key="item.userId"
                                        :value="item.userId"
                                        :label="item.userName">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item :label="$t('base.materiel.type')">
                            <el-select v-model="listQuery.classificationId" clearable filterable :style="{width:'100%'}">
                                <el-option
                                        v-for="item in materielCategoryList"
                                        :key="item.materielCategoryId"
                                        :value="item.materielCategoryId"
                                        :label="item.materielCategoryName">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item :label="$t('base.materiel.materielName')">
                            <el-input v-model="listQuery.materielName" maxlength="25" clearable @keyup.enter.native="searchCondition"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item :label="$t('commons.startTime')">
                            <el-date-picker
                                    @change="startPicker"
                                    clearable
                                    :style="{width:'100%'}"
                                    v-model="listQuery.reportStartDate"
                                    format="yyyy-MM-dd"
                                    :placeholder="$t('commons.startTime')">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item :label="$t('commons.endTime')">
                            <el-date-picker
                                    @change="endPicker"
                                    clearable
                                    :style="{width:'100%'}"
                                    v-model="listQuery.reportEndDate"
                                    format="yyyy-MM-dd"
                                    :placeholder="$t('commons.endTime')">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
                <div class="filter-container">
                    <el-button type="primary" icon="el-icon-search" :loading="loading" @click="searchCondition">{{$t('commons.search')}}</el-button>
                    <el-button type="primary" icon="el-icon-brush" plain @click="clearForm">{{$t('commons.clear')}}</el-button>
                    <el-button type="primary" icon="el-icon-download" plain @click="exportOrderMateriel">{{$t('commons.export')}}</el-button>
                </div>

                <el-table
                        v-loading="loading"
                        element-loading-text="Loading"
                        :data="list"
                        border
                        stripe
                        highlight-current-row>
                    <el-table-column :label="$t('base.project.name')" :show-overflow-tooltip='true'>
                        <template slot-scope="s">
                            <span>{{ s.row.projectName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('order.orderCode')" :show-overflow-tooltip='true'>
                        <template slot-scope="s">
                            <a style="color: #45a0ff;" @click="showOrderDetail(s.row.orderId)">{{ s.row.orderCode }}</a>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('base.materiel.materielName')" :show-overflow-tooltip='true'>
                        <template slot-scope="s">
                            <span>{{ s.row.materielName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('facilityHome.number')" width="80">
                        <template slot-scope="s">
                            <span>{{ s.row.number }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('base.materiel.unit')" width="80">
                        <template slot-scope="s">
                            <span>{{ s.row.specifications }}</span>
                        </template>
                    </el-table-column>
                    <!--
                    <el-table-column :label="$t('base.materiel.mode')" width="120">
                        <template slot-scope="s">
                            <span>{{ s.row.model }}</span>
                        </template>
                    </el-table-column>
                    -->
                    <el-table-column :label="$t('order.appPerson')" :show-overflow-tooltip='true'>
                        <template slot-scope="s">
                            <span>{{ s.row.userName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('order.appDept')" :show-overflow-tooltip='true'>
                        <template slot-scope="s">
                            <span>{{ s.row.deptName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('order.appTime')" width="160">
                        <template slot-scope="s">
                            <span v-if="s.row.creationDate != null"> {{ s.row.creationDate | dateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('order.auditPerson')" :show-overflow-tooltip='true'>
                        <template slot-scope="s">
                            <span>{{ s.row.auditPersonName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('order.auditDept')" :show-overflow-tooltip='true'>
                        <template slot-scope="s">
                            <span>{{ s.row.auditDeptName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('order.auditTime')" width="160">
                        <template slot-scope="s">
                            <span v-if="s.row.auditTime != null"> {{ s.row.auditTime | dateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
                        </template>
                    </el-table-column>
                </el-table>

                <pagination v-show="total>0" :total="total" :page.sync="listQuery.current"
                            :limit.sync="listQuery.rowCount"
                            @pagination="getMaterialDrawingPage"/>
            </el-tab-pane>
            <el-tab-pane v-if="detailTab" :label="$t('order.details')" :name="detailTab.tabName" :key="detailTab.tabName" closable>
                <order-detail v-bind:orderRow="order" :key="order.orderId"></order-detail>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import{
        getCompanyList,
        getProjectList,
        getResDeptName,
        getEmployeeList,
    }from '@/api/business/order/tenant/order'
    import{
        getMaterialDrawingPage,
        getMaterielCategoryList,
        exportOrderMateriel
    }from '@/api/business/order/tenant/materialDrawing'
    import Pagination from '@/components/Pagination'
    import OrderDetail from '@/views/business/order/orderQuery/order/tenant/orderDetail'
    import store from '@/store/index'

    const defineTab = {
        listTab : {
            tabName: "1",
        },
        detailTab :  {
            tabName: "2",
        },
    }

    export default {
        name: "MaterialDrawing",
        components:{
            Pagination,
            OrderDetail
        },
        data(){
            return{
                activeTabName : defineTab.listTab.tabName,
                detailTab : null,
                loading: true,
                isAble:true,
                companyList:[],
                projectList:[],
                departmentList:[],
                employeeList:[],
                materielCategoryList:[],
                total:0,
                list:null,
                listQuery:{
                    current:1,
                    rowCount:10,
                    companyId:null,
                    projectId:null,
                    reportStartDate:null,
                    reportEndDate:null,
                    reportDeptId:null,
                    reportUserId:null,
                    classificationId:null,
                    materielName:null
                },
                form:{
                    creationDate:null,
                    projectName:null,
                    orderDesc:null,
                    model:null,
                    specifications:null,
                    number:null,
                    userName:null,
                    deptName:null,
                },
                order: {
                    orderId: null,
                },
            }
        },
        created(){
            this.searchCondition()
            this.getCompanyList()
            this.getEmployeeList()
            this.getMaterielCategoryList()
        },
        computed: {
            listTab() {
                return defineTab.listTab;
            }
        },
        methods:{
            // 关闭项Tab
            removeTab(tabName) {
                this.activeTabName = defineTab.listTab.tabName;
                if(tabName == "2") {
                    this.detailTab = null;
                }
            },
            // 展示工单详情
            showOrderDetail(orderId){
                // 打开tab
//              this.detailTab = defineTab.detailTab;
//              this.order.orderId = orderId;
//              this.activeTabName = defineTab.detailTab.tabName;
                let params = {
			        path: "/iot/orderQuery/detail",
			        query: {
			          orderId: orderId,
			        },
		      	};
		      	this.$router.push(params);
                
            },
            clearForm(){
                this.isAble = true;
                this.listQuery.companyId = null;
                this.listQuery.projectId = null;
                this.listQuery.reportStartDate = null;
                this.listQuery.reportEndDate = null;
                this.listQuery.reportDeptId = null;
                this.listQuery.reportUserId = null;
                this.listQuery.classificationId = null;
                this.listQuery.materielName = null;
            },
            startPicker(time){
                this.listQuery.reportStartDate = time.getTime()
            },
            endPicker(time){
                this.listQuery.reportEndDate = time.getTime()
            },
            getMaterielCategoryList(){
                getMaterielCategoryList().then(res=>{
                    this.materielCategoryList = res;
                })
            },
            searchCondition(){
                this.listQuery.current = 1;
                this.getMaterialDrawingPage()
            },
            getMaterialDrawingPage(){
                this.loading = true;
                getMaterialDrawingPage(this.listQuery).then(res=>{
                    this.list = res.rows
                    this.total = res.total
                }).finally(() => {
                    this.loading = false;
                })
            },
            getCompanyList(){
                getCompanyList().then(res=>{
                    this.companyList = res
                })
            },
            changeCompany(companyId){
                if(companyId){
                    this.listQuery.projectId = null;
                    this.listQuery.reportDeptId = null;
                    this.isAble = false;
                    getProjectList({"companyId":companyId}).then(res=>{
                        this.projectList = res
                    })

                    getResDeptName({"companyId":companyId}).then(res=>{
                        this.departmentList = res
                    })
                }else{
                    this.departmentList = [];
                    this.projectList = [];
                    this.listQuery.projectId = null;
                    this.listQuery.reportDeptId = null;
                    this.isAble = true;
                }

            },
            getEmployeeList(){
                getEmployeeList().then(res=>{
                    this.employeeList = res
                })
            },
            clearEmpty(field) {
                this.listQuery[field] = null
            },
            exportOrderMateriel(){
                exportOrderMateriel(this.listQuery).then((msg)=>{
                    this.$message({type: 'success', message: this.$t('message.operationSuccess')});
                    let exportObj = {
                        taskId : msg,
                        taskName : "OrderMateriel",
                        taskStatus : 0,
                        rootPath : 'orderPath'
                    };
                    //将导出任务丢入导出任务列表中
                    store.dispatch('PushExportNotice',exportObj);
                })
            },
        }
    }
</script>
