import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 加载物料领用列表
 * 加载物料领用列表
 * @param params
 */
export function getMaterialDrawingPage(params) {
    return request({
        url: envInfo.bgApp.orderPath + '/tenant/orderMateriel/conditionalQuery',
        method: 'get',
        params
    })
}

/**
 * 物料分类查询
 */
export function getMaterielCategoryList(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/materielcategory/list',
        method: 'get',
        params
    })
}

/**
 * 物料领用导出
 * @param params
 */
export function exportOrderMateriel(params) {
    return request({
        url: envInfo.bgApp.orderPath + '/tenant/orderMateriel/export',
        method: 'get',
        params
    })
}
